<template>
  <div class="invite-user">
    <div class="invite-funnel">
      <div class="invite-funnel__main-block">
        <div class="invite-funnel__main-block__inner">
          <div class="invite-funnel__main-block__heading">
            <a href="https://www.letsconnect.at/" class="invite-funnel__main-block__heading__logo-wrapper">
              <img src="@/assets/images/register-funnel/vega_logo.svg" alt="Logo" class="invite-funnel__main-block__heading__logo-img" />
            </a>
          </div>
          <div class="invite-funnel__main-block__body">
            <div class="invite-funnel__main-block__flow">
              <div class="invite-funnel__main-block__flow__progress-bar-wrapper">
                <div
                  class="invite-funnel__main-block__flow__progress-bar"
                  :style="{
                    width: `${(currentStep / (totalCountOfSteps + 1)) * 100}%`
                  }"
                />
              </div>

              <form>
                <div class="invite-funnel__main-block__flow__heading">
                  <div class="invite-funnel__main-block__flow__heading__title">{{ $t('vue.niceToMeetYou') }} {{ name }}!</div>
                  <div class="invite-funnel__main-block__flow__heading__description">{{ $t('vue.letsSetupYourPassword') }}</div>
                </div>

                <div class="invite-funnel__main-block__flow__content">
                  <div class="invite-funnel__main-block__flow__content__form">
                    <div class="invite-funnel__main-block__flow__content__form__fields">
                      <div class="invite-funnel__main-block__flow__content__form__fields__field">
                        <div class="invite-funnel__main-block__flow__content__form__fields__field__inner">
                          <input
                            ref="password"
                            type="password"
                            v-model="password"
                            @focus="onPasswordFocus"
                            @blur="onPasswordBlur"
                            autocomplete="on"
                            class="invite-funnel__main-block__flow__content__form__fields__field__input"
                            v-validate.continues="'required|min:6|max:128|atLeastOneNumber|atLeastOneUppercase|atLeastOneSpecialCharacter'"
                            :name="$t('inputs.password')"
                            :label-placeholder="$t('inputs.password')"
                          />

                          <div
                            class="invite-funnel__main-block__flow__content__form__fields__field__label"
                            :class="{
                              'invite-funnel__main-block__flow__content__form__fields__field__label--moved':
                                (password && password.length > 0) || isPasswordFocused
                            }"
                            @click="$refs.password.focus()"
                          >
                            {{ $t('inputs.password') }}
                          </div>
                        </div>

                        <span
                          v-if="
                            errors &&
                            errors.items &&
                            errors.items.find((item) => item.field === $t('inputs.password') && ['required', 'max'].includes(item.rule))
                          "
                          class="text-danger text-lg flex items-center"
                          style="margin-top: 10px"
                        >
                          <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.password')) }}
                        </span>

                        <div class="invite-funnel__main-block__flow__content__form__fields__field__rules-wrapper">
                          <div
                            class="invite-funnel__main-block__flow__content__form__fields__field__rule"
                            :class="{
                              'invite-funnel__main-block__flow__content__form__fields__field__rule--valid': password.length >= 6,
                              'invite-funnel__main-block__flow__content__form__fields__field__rule--invalid':
                                errors && errors.items && errors.items.find((item) => item.field === $t('inputs.password') && ['min'].includes(item.rule))
                            }"
                          >
                            <check-icon
                              v-if="password.length >= 6"
                              color="rgb(65, 184, 131)"
                              class="invite-funnel__main-block__flow__content__form__fields__field__rule__icon"
                              :width="16"
                              :height="16"
                            />

                            {{ $t('inputs.minimumCharacters') }}
                          </div>

                          <div
                            class="invite-funnel__main-block__flow__content__form__fields__field__rule"
                            :class="{
                              'invite-funnel__main-block__flow__content__form__fields__field__rule--valid':
                                password.length &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneUppercase'].includes(item.rule))
                                ),
                              'invite-funnel__main-block__flow__content__form__fields__field__rule--invalid':
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneUppercase'].includes(item.rule))
                            }"
                          >
                            <check-icon
                              v-if="
                                password.length &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneUppercase'].includes(item.rule))
                                )
                              "
                              color="rgb(65, 184, 131)"
                              class="invite-funnel__main-block__flow__content__form__fields__field__rule__icon"
                              :width="16"
                              :height="16"
                            />

                            {{ $t('inputs.atLeastUppercase') }}
                          </div>

                          <div
                            class="invite-funnel__main-block__flow__content__form__fields__field__rule"
                            :class="{
                              'invite-funnel__main-block__flow__content__form__fields__field__rule--valid':
                                password.length &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneNumber'].includes(item.rule))
                                ),
                              'invite-funnel__main-block__flow__content__form__fields__field__rule--invalid':
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneNumber'].includes(item.rule))
                            }"
                          >
                            <check-icon
                              v-if="
                                password.length &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneNumber'].includes(item.rule))
                                )
                              "
                              color="rgb(65, 184, 131)"
                              class="invite-funnel__main-block__flow__content__form__fields__field__rule__icon"
                              :width="16"
                              :height="16"
                            />

                            {{ $t('inputs.atLeastNumber') }}
                          </div>

                          <div
                            class="invite-funnel__main-block__flow__content__form__fields__field__rule"
                            :class="{
                              'invite-funnel__main-block__flow__content__form__fields__field__rule--valid':
                                password.length &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneSpecialCharacter'].includes(item.rule))
                                ),
                              'invite-funnel__main-block__flow__content__form__fields__field__rule--invalid':
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneSpecialCharacter'].includes(item.rule))
                            }"
                          >
                            <check-icon
                              v-if="
                                password.length &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.password') && ['atLeastOneSpecialCharacter'].includes(item.rule))
                                )
                              "
                              color="rgb(65, 184, 131)"
                              class="invite-funnel__main-block__flow__content__form__fields__field__rule__icon"
                              :width="16"
                              :height="16"
                            />

                            {{ $t('inputs.atLeastSpecialCharacter') }}
                          </div>
                        </div>
                      </div>

                      <div class="invite-funnel__main-block__flow__content__form__fields__field">
                        <div class="invite-funnel__main-block__flow__content__form__fields__field__inner">
                          <div class="invite-funnel__main-block__flow__content__form__fields__field__inner">
                            <input
                              ref="confirmPassword"
                              type="password"
                              v-model="confirmPassword"
                              @focus="onConfirmPasswordFocus"
                              @blur="onConfirmPasswordBlur"
                              autocomplete="on"
                              class="invite-funnel__main-block__flow__content__form__fields__field__input"
                              v-validate.continues="'required|confirmed:password|verifyPassword'"
                              :name="$t('inputs.confirmPassword')"
                              :label-placeholder="$t('inputs.confirmPassword')"
                              @keyup.enter="nextStep"
                            />

                            <div
                              class="invite-funnel__main-block__flow__content__form__fields__field__label"
                              :class="{
                                'invite-funnel__main-block__flow__content__form__fields__field__label--moved':
                                  (confirmPassword && confirmPassword.length > 0) || isConfirmPasswordFocused
                              }"
                              @click="$refs.confirmPassword.focus()"
                            >
                              {{ $t('inputs.confirmPassword') }}
                            </div>
                          </div>
                        </div>

                        <span
                          v-if="
                            errors &&
                            errors.items &&
                            errors.items.find((item) => item.field === $t('inputs.confirmPassword') && ['required', 'max'].includes(item.rule))
                          "
                          class="text-danger text-lg flex items-center"
                          style="margin-top: 10px"
                        >
                          <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.confirmPassword')) }}
                        </span>

                        <div class="invite-funnel__main-block__flow__content__form__fields__field__rules-wrapper">
                          <div
                            class="invite-funnel__main-block__flow__content__form__fields__field__rule"
                            :class="{
                              'invite-funnel__main-block__flow__content__form__fields__field__rule--valid':
                                confirmPassword.length > 0 &&
                                confirmPassword === password &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.confirmPassword') && ['confirmed'].includes(item.rule))
                                ),
                              'invite-funnel__main-block__flow__content__form__fields__field__rule--invalid':
                                errors &&
                                errors.items &&
                                errors.items.find((item) => item.field === $t('inputs.confirmPassword') && ['confirmed'].includes(item.rule))
                            }"
                          >
                            <check-icon
                              v-if="
                                confirmPassword.length > 0 &&
                                confirmPassword === password &&
                                !(
                                  errors &&
                                  errors.items &&
                                  errors.items.find((item) => item.field === $t('inputs.confirmPassword') && ['confirmed'].includes(item.rule))
                                )
                              "
                              color="rgb(65, 184, 131)"
                              class="invite-funnel__main-block__flow__content__form__fields__field__rule__icon"
                              :width="16"
                              :height="16"
                            />

                            {{ $t('inputs.confirmPasswordMatch') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div class="invite-funnel__main-block__flow__content__next-button-wrapper">
                <div class="invite-funnel__main-block__flow__content__next-button" @click="onFinish">
                  <template>{{ $t('vue.finish') }}</template>

                  <img
                    src="@/assets/images/register-funnel/forward-arrow.png"
                    alt="finish"
                    class="invite-funnel__main-block__flow__content__next-button__icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="invite-funnel__info-block">
        <div class="invite-funnel__info-block__inner">
          <TeamIcon />
          <div class="invite-funnel__info-block__title">{{ $t('invite-user.welcome-heading') }}</div>
          <div class="invite-funnel__info-block__description">{{ $t('invite-user.welcome-message') }}</div>
        </div>
      </div>
    </div>

    <div v-if="isLoading" class="invite-funnel__loading">
      <div class="invite-funnel__loading__loader">
        <span class="invite-funnel__loading__loader__spinner" />
        <div class="invite-funnel__loading__loader__text">{{ $t('vue.loading') }}</div>
      </div>
    </div>

    <div v-if="isProcessing" class="invite-funnel__loading">
      <div class="invite-funnel__loading__loader">
        <span class="invite-funnel__loading__loader__spinner" />
        <div class="invite-funnel__loading__loader__text">{{ $t('vue.processing') }}</div>
      </div>
    </div>

    <template v-if="passwordUpdatedSuccessfully">
      <div class="mollie-checkout__loading">
        <div class="mollie-checkout__loading__loader">
          <img src="@/assets/images/register-funnel/check.png" alt="Success" />
          <div class="mollie-checkout__loading__loader__title">{{ $t('vue.allGood') }}</div>
          <div class="mollie-checkout__loading__loader__text">{{ $t('vue.redirectToAccount') }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import TeamIcon from './TeamIcon.vue'
import CheckIcon from '@/components/icons/CheckIcon'

export default {
  name: 'SetPasswordInvitedUser',
  components: {
    TeamIcon,
    CheckIcon
  },
  data() {
    return {
      currentStep: 1,
      totalCountOfSteps: 1,
      password: '',
      confirmPassword: '',
      isPasswordFocused: false,
      isConfirmPasswordFocused: false,
      isLoading: true,
      isProcessing: false,
      passwordUpdatedSuccessfully: false
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    }),
    name() {
      if (!this.activeUserInfo) return null
      let name = this.activeUserInfo.firstname
      if (!name) {
        name = this.activeUserInfo.lastname
      }
      if (!name) return null
      return `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`
    }
  },

  mounted() {
    if (this.activeUserInfo && this.activeUserInfo.firstname) {
      this.isLoading = false
    }
  },

  watch: {
    name() {
      if (this.name) {
        this.isLoading = false
      }
    }
  },

  methods: {
    onPasswordFocus() {
      this.isPasswordFocused = true
    },
    onPasswordBlur() {
      this.isPasswordFocused = false
    },
    onConfirmPasswordFocus() {
      this.isConfirmPasswordFocused = true
    },
    onConfirmPasswordBlur() {
      this.isConfirmPasswordFocused = false
    },
    reset() {
      this.password = ''
      this.confirmPassword = ''
      setTimeout(() => this.errors.clear(), 50)
    },
    async onFinish() {
      const vm = this
      const validate = await this.$validator.validateAll()
      if (validate) {
        vm.isProcessing = true
        const user = this.$firebase.auth().currentUser
        await user
          .updatePassword(this.password)
          .then(async () => {
            const userInfo = {
              hasSetPassword: true,
              displayedSetPassword: true
            }
            const userRef = await vm.$db.collection('users').doc(user.uid)
            await userRef.set(userInfo, { merge: true })
            vm.$store.commit('UPDATE_USER_INFO', userInfo)
            vm.passwordUpdatedSuccessfully = true

            setTimeout(() => {
              vm.$router.push({ name: 'home' })
            }, 3000)
            vm.reset()
          })
          .catch((error) => {
            this.$vs.notify({
              time: 8800,
              title: vm.$i18n.t('vue.error'),
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './invite-funnel.scss';
</style>
