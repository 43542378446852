var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "invite-user" },
    [
      _c("div", { staticClass: "invite-funnel" }, [
        _c("div", { staticClass: "invite-funnel__main-block" }, [
          _c("div", { staticClass: "invite-funnel__main-block__inner" }, [
            _vm._m(0),
            _c("div", { staticClass: "invite-funnel__main-block__body" }, [
              _c("div", { staticClass: "invite-funnel__main-block__flow" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "invite-funnel__main-block__flow__progress-bar-wrapper",
                  },
                  [
                    _c("div", {
                      staticClass:
                        "invite-funnel__main-block__flow__progress-bar",
                      style: {
                        width:
                          (_vm.currentStep / (_vm.totalCountOfSteps + 1)) *
                            100 +
                          "%",
                      },
                    }),
                  ]
                ),
                _c("form", [
                  _c(
                    "div",
                    { staticClass: "invite-funnel__main-block__flow__heading" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "invite-funnel__main-block__flow__heading__title",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("vue.niceToMeetYou")) +
                              " " +
                              _vm._s(_vm.name) +
                              "!"
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "invite-funnel__main-block__flow__heading__description",
                        },
                        [_vm._v(_vm._s(_vm.$t("vue.letsSetupYourPassword")))]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "invite-funnel__main-block__flow__content" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "invite-funnel__main-block__flow__content__form",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "invite-funnel__main-block__flow__content__form__fields",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "invite-funnel__main-block__flow__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "invite-funnel__main-block__flow__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.password,
                                            expression: "password",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate.continues",
                                            value:
                                              "required|min:6|max:128|atLeastOneNumber|atLeastOneUppercase|atLeastOneSpecialCharacter",
                                            expression:
                                              "'required|min:6|max:128|atLeastOneNumber|atLeastOneUppercase|atLeastOneSpecialCharacter'",
                                            modifiers: { continues: true },
                                          },
                                        ],
                                        ref: "password",
                                        staticClass:
                                          "invite-funnel__main-block__flow__content__form__fields__field__input",
                                        attrs: {
                                          type: "password",
                                          autocomplete: "on",
                                          name: _vm.$t("inputs.password"),
                                          "label-placeholder":
                                            _vm.$t("inputs.password"),
                                        },
                                        domProps: { value: _vm.password },
                                        on: {
                                          focus: _vm.onPasswordFocus,
                                          blur: _vm.onPasswordBlur,
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.password = $event.target.value
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "invite-funnel__main-block__flow__content__form__fields__field__label",
                                          class: {
                                            "invite-funnel__main-block__flow__content__form__fields__field__label--moved":
                                              (_vm.password &&
                                                _vm.password.length > 0) ||
                                              _vm.isPasswordFocused,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.password.focus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.$t("inputs.password")
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.errors &&
                                  _vm.errors.items &&
                                  _vm.errors.items.find(function (item) {
                                    return (
                                      item.field ===
                                        _vm.$t("inputs.password") &&
                                      ["required", "max"].includes(item.rule)
                                    )
                                  })
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.password")
                                              )
                                            ) + "\n                      "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "invite-funnel__main-block__flow__content__form__fields__field__rules-wrapper",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule",
                                          class: {
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule--valid":
                                              _vm.password.length >= 6,
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule--invalid":
                                              _vm.errors &&
                                              _vm.errors.items &&
                                              _vm.errors.items.find(function (
                                                item
                                              ) {
                                                return (
                                                  item.field ===
                                                    _vm.$t("inputs.password") &&
                                                  ["min"].includes(item.rule)
                                                )
                                              }),
                                          },
                                        },
                                        [
                                          _vm.password.length >= 6
                                            ? _c("check-icon", {
                                                staticClass:
                                                  "invite-funnel__main-block__flow__content__form__fields__field__rule__icon",
                                                attrs: {
                                                  color: "rgb(65, 184, 131)",
                                                  width: 16,
                                                  height: 16,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n\n                          " +
                                              _vm._s(
                                                _vm.$t(
                                                  "inputs.minimumCharacters"
                                                )
                                              ) +
                                              "\n                        "
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule",
                                          class: {
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule--valid":
                                              _vm.password.length &&
                                              !(
                                                _vm.errors &&
                                                _vm.errors.items &&
                                                _vm.errors.items.find(function (
                                                  item
                                                ) {
                                                  return (
                                                    item.field ===
                                                      _vm.$t(
                                                        "inputs.password"
                                                      ) &&
                                                    [
                                                      "atLeastOneUppercase",
                                                    ].includes(item.rule)
                                                  )
                                                })
                                              ),
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule--invalid":
                                              _vm.errors &&
                                              _vm.errors.items &&
                                              _vm.errors.items.find(function (
                                                item
                                              ) {
                                                return (
                                                  item.field ===
                                                    _vm.$t("inputs.password") &&
                                                  [
                                                    "atLeastOneUppercase",
                                                  ].includes(item.rule)
                                                )
                                              }),
                                          },
                                        },
                                        [
                                          _vm.password.length &&
                                          !(
                                            _vm.errors &&
                                            _vm.errors.items &&
                                            _vm.errors.items.find(function (
                                              item
                                            ) {
                                              return (
                                                item.field ===
                                                  _vm.$t("inputs.password") &&
                                                [
                                                  "atLeastOneUppercase",
                                                ].includes(item.rule)
                                              )
                                            })
                                          )
                                            ? _c("check-icon", {
                                                staticClass:
                                                  "invite-funnel__main-block__flow__content__form__fields__field__rule__icon",
                                                attrs: {
                                                  color: "rgb(65, 184, 131)",
                                                  width: 16,
                                                  height: 16,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n\n                          " +
                                              _vm._s(
                                                _vm.$t(
                                                  "inputs.atLeastUppercase"
                                                )
                                              ) +
                                              "\n                        "
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule",
                                          class: {
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule--valid":
                                              _vm.password.length &&
                                              !(
                                                _vm.errors &&
                                                _vm.errors.items &&
                                                _vm.errors.items.find(function (
                                                  item
                                                ) {
                                                  return (
                                                    item.field ===
                                                      _vm.$t(
                                                        "inputs.password"
                                                      ) &&
                                                    [
                                                      "atLeastOneNumber",
                                                    ].includes(item.rule)
                                                  )
                                                })
                                              ),
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule--invalid":
                                              _vm.errors &&
                                              _vm.errors.items &&
                                              _vm.errors.items.find(function (
                                                item
                                              ) {
                                                return (
                                                  item.field ===
                                                    _vm.$t("inputs.password") &&
                                                  ["atLeastOneNumber"].includes(
                                                    item.rule
                                                  )
                                                )
                                              }),
                                          },
                                        },
                                        [
                                          _vm.password.length &&
                                          !(
                                            _vm.errors &&
                                            _vm.errors.items &&
                                            _vm.errors.items.find(function (
                                              item
                                            ) {
                                              return (
                                                item.field ===
                                                  _vm.$t("inputs.password") &&
                                                ["atLeastOneNumber"].includes(
                                                  item.rule
                                                )
                                              )
                                            })
                                          )
                                            ? _c("check-icon", {
                                                staticClass:
                                                  "invite-funnel__main-block__flow__content__form__fields__field__rule__icon",
                                                attrs: {
                                                  color: "rgb(65, 184, 131)",
                                                  width: 16,
                                                  height: 16,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n\n                          " +
                                              _vm._s(
                                                _vm.$t("inputs.atLeastNumber")
                                              ) +
                                              "\n                        "
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule",
                                          class: {
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule--valid":
                                              _vm.password.length &&
                                              !(
                                                _vm.errors &&
                                                _vm.errors.items &&
                                                _vm.errors.items.find(function (
                                                  item
                                                ) {
                                                  return (
                                                    item.field ===
                                                      _vm.$t(
                                                        "inputs.password"
                                                      ) &&
                                                    [
                                                      "atLeastOneSpecialCharacter",
                                                    ].includes(item.rule)
                                                  )
                                                })
                                              ),
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule--invalid":
                                              _vm.errors &&
                                              _vm.errors.items &&
                                              _vm.errors.items.find(function (
                                                item
                                              ) {
                                                return (
                                                  item.field ===
                                                    _vm.$t("inputs.password") &&
                                                  [
                                                    "atLeastOneSpecialCharacter",
                                                  ].includes(item.rule)
                                                )
                                              }),
                                          },
                                        },
                                        [
                                          _vm.password.length &&
                                          !(
                                            _vm.errors &&
                                            _vm.errors.items &&
                                            _vm.errors.items.find(function (
                                              item
                                            ) {
                                              return (
                                                item.field ===
                                                  _vm.$t("inputs.password") &&
                                                [
                                                  "atLeastOneSpecialCharacter",
                                                ].includes(item.rule)
                                              )
                                            })
                                          )
                                            ? _c("check-icon", {
                                                staticClass:
                                                  "invite-funnel__main-block__flow__content__form__fields__field__rule__icon",
                                                attrs: {
                                                  color: "rgb(65, 184, 131)",
                                                  width: 16,
                                                  height: 16,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n\n                          " +
                                              _vm._s(
                                                _vm.$t(
                                                  "inputs.atLeastSpecialCharacter"
                                                )
                                              ) +
                                              "\n                        "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "invite-funnel__main-block__flow__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "invite-funnel__main-block__flow__content__form__fields__field__inner",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "invite-funnel__main-block__flow__content__form__fields__field__inner",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.confirmPassword,
                                                expression: "confirmPassword",
                                              },
                                              {
                                                name: "validate",
                                                rawName: "v-validate.continues",
                                                value:
                                                  "required|confirmed:password|verifyPassword",
                                                expression:
                                                  "'required|confirmed:password|verifyPassword'",
                                                modifiers: { continues: true },
                                              },
                                            ],
                                            ref: "confirmPassword",
                                            staticClass:
                                              "invite-funnel__main-block__flow__content__form__fields__field__input",
                                            attrs: {
                                              type: "password",
                                              autocomplete: "on",
                                              name: _vm.$t(
                                                "inputs.confirmPassword"
                                              ),
                                              "label-placeholder": _vm.$t(
                                                "inputs.confirmPassword"
                                              ),
                                            },
                                            domProps: {
                                              value: _vm.confirmPassword,
                                            },
                                            on: {
                                              focus: _vm.onConfirmPasswordFocus,
                                              blur: _vm.onConfirmPasswordBlur,
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.nextStep.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.confirmPassword =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "invite-funnel__main-block__flow__content__form__fields__field__label",
                                              class: {
                                                "invite-funnel__main-block__flow__content__form__fields__field__label--moved":
                                                  (_vm.confirmPassword &&
                                                    _vm.confirmPassword.length >
                                                      0) ||
                                                  _vm.isConfirmPasswordFocused,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.confirmPassword.focus()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                            " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "inputs.confirmPassword"
                                                    )
                                                  ) +
                                                  "\n                          "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.errors &&
                                  _vm.errors.items &&
                                  _vm.errors.items.find(function (item) {
                                    return (
                                      item.field ===
                                        _vm.$t("inputs.confirmPassword") &&
                                      ["required", "max"].includes(item.rule)
                                    )
                                  })
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.confirmPassword")
                                              )
                                            ) + "\n                      "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "invite-funnel__main-block__flow__content__form__fields__field__rules-wrapper",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule",
                                          class: {
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule--valid":
                                              _vm.confirmPassword.length > 0 &&
                                              _vm.confirmPassword ===
                                                _vm.password &&
                                              !(
                                                _vm.errors &&
                                                _vm.errors.items &&
                                                _vm.errors.items.find(function (
                                                  item
                                                ) {
                                                  return (
                                                    item.field ===
                                                      _vm.$t(
                                                        "inputs.confirmPassword"
                                                      ) &&
                                                    ["confirmed"].includes(
                                                      item.rule
                                                    )
                                                  )
                                                })
                                              ),
                                            "invite-funnel__main-block__flow__content__form__fields__field__rule--invalid":
                                              _vm.errors &&
                                              _vm.errors.items &&
                                              _vm.errors.items.find(function (
                                                item
                                              ) {
                                                return (
                                                  item.field ===
                                                    _vm.$t(
                                                      "inputs.confirmPassword"
                                                    ) &&
                                                  ["confirmed"].includes(
                                                    item.rule
                                                  )
                                                )
                                              }),
                                          },
                                        },
                                        [
                                          _vm.confirmPassword.length > 0 &&
                                          _vm.confirmPassword ===
                                            _vm.password &&
                                          !(
                                            _vm.errors &&
                                            _vm.errors.items &&
                                            _vm.errors.items.find(function (
                                              item
                                            ) {
                                              return (
                                                item.field ===
                                                  _vm.$t(
                                                    "inputs.confirmPassword"
                                                  ) &&
                                                ["confirmed"].includes(
                                                  item.rule
                                                )
                                              )
                                            })
                                          )
                                            ? _c("check-icon", {
                                                staticClass:
                                                  "invite-funnel__main-block__flow__content__form__fields__field__rule__icon",
                                                attrs: {
                                                  color: "rgb(65, 184, 131)",
                                                  width: 16,
                                                  height: 16,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n\n                          " +
                                              _vm._s(
                                                _vm.$t(
                                                  "inputs.confirmPasswordMatch"
                                                )
                                              ) +
                                              "\n                        "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "invite-funnel__main-block__flow__content__next-button-wrapper",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "invite-funnel__main-block__flow__content__next-button",
                        on: { click: _vm.onFinish },
                      },
                      [
                        [_vm._v(_vm._s(_vm.$t("vue.finish")))],
                        _c("img", {
                          staticClass:
                            "invite-funnel__main-block__flow__content__next-button__icon",
                          attrs: {
                            src: require("@/assets/images/register-funnel/forward-arrow.png"),
                            alt: "finish",
                          },
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "invite-funnel__info-block" }, [
          _c(
            "div",
            { staticClass: "invite-funnel__info-block__inner" },
            [
              _c("TeamIcon"),
              _c("div", { staticClass: "invite-funnel__info-block__title" }, [
                _vm._v(_vm._s(_vm.$t("invite-user.welcome-heading"))),
              ]),
              _c(
                "div",
                { staticClass: "invite-funnel__info-block__description" },
                [_vm._v(_vm._s(_vm.$t("invite-user.welcome-message")))]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm.isLoading
        ? _c("div", { staticClass: "invite-funnel__loading" }, [
            _c("div", { staticClass: "invite-funnel__loading__loader" }, [
              _c("span", {
                staticClass: "invite-funnel__loading__loader__spinner",
              }),
              _c(
                "div",
                { staticClass: "invite-funnel__loading__loader__text" },
                [_vm._v(_vm._s(_vm.$t("vue.loading")))]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.isProcessing
        ? _c("div", { staticClass: "invite-funnel__loading" }, [
            _c("div", { staticClass: "invite-funnel__loading__loader" }, [
              _c("span", {
                staticClass: "invite-funnel__loading__loader__spinner",
              }),
              _c(
                "div",
                { staticClass: "invite-funnel__loading__loader__text" },
                [_vm._v(_vm._s(_vm.$t("vue.processing")))]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.passwordUpdatedSuccessfully
        ? [
            _c("div", { staticClass: "mollie-checkout__loading" }, [
              _c("div", { staticClass: "mollie-checkout__loading__loader" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/register-funnel/check.png"),
                    alt: "Success",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "mollie-checkout__loading__loader__title" },
                  [_vm._v(_vm._s(_vm.$t("vue.allGood")))]
                ),
                _c(
                  "div",
                  { staticClass: "mollie-checkout__loading__loader__text" },
                  [_vm._v(_vm._s(_vm.$t("vue.redirectToAccount")))]
                ),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "invite-funnel__main-block__heading" }, [
      _c(
        "a",
        {
          staticClass: "invite-funnel__main-block__heading__logo-wrapper",
          attrs: { href: "https://www.letsconnect.at/" },
        },
        [
          _c("img", {
            staticClass: "invite-funnel__main-block__heading__logo-img",
            attrs: {
              src: require("@/assets/images/register-funnel/vega_logo.svg"),
              alt: "Logo",
            },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }